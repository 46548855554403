// src/lib/filesToEtlUnits/transforms/headerview-field.js
// @flow

/**
 * @module /lib/filesToEtlUnits/headerview-field
 *
 * @description
 * The data structure for a field within a header-view.
 *
 * @todo This note may be outdated.
 * UI configuration of codomain-reducer needs to be
 * implemented; issue: 1:1 with a codomain, where more than one can
 * exist in a header view. For now, the prop is present at the source-level
 * as a placeholder. Defaults will be implemented by the backend.
 *
 * Note: the codomain_reducer is also a etlUnit concept (not etlField)
 *
 */

import { uniqueArray } from '../etl-field-helper';
import initialTimeProp from './span/initial-time-prop';
import * as H from '../headerview-helpers';
import { PURPOSE_TYPES as TYPES, SOURCE_TYPES } from '../../sum-types';
import { ValueError, ApiTncError } from '../../LuciErrors';
import { colors } from '../../../constants/variables';

const COLOR = colors.green;

/* eslint-disable no-console */

/**
 * The wide-to-long configuration process needs a dummy data set
 * during the phase when factor and measurement names have not been provided.
 */
export const dummyField = {
  enabled: true, // fixed when included in wideToLongFields config
  'source-type': 'DUMMY',
  'field-alias': '', // ui using this config
  purpose: TYPES.MCOMP,
  format: '',
  levels: [],
  time: { interval: { count: 1, unit: null } },
};

/**
 *
 * One of three sources for a field
 *   👉  file (type: RAW)
 *   👉  implied (type: IMPLIED)
 *   👉  wideToLongFields (type: WIDE)
 *
 * Field template for headerview
 *
 * Used to both initialize new file -> header fields
 * and fields derived from "group-by-file".
 *
 * Note: this is part of the journey to the backend data extraction
 * used to instantiate the etl-warehouse.
 *
 * ✅ file field -> header field
 * ⬜ [header field] -> [source] for a etl-field
 * ⬜ [etl-field] -> etl-unit
 *
 * @function
 * @param {Objecet} file User-selected file
 * @param {Objecet} field Field within a field description generated by the API
 * @return {Objecet} field Field::headerview
 */
export const fieldFromFileField = (file, field) => {
  // field can be raw data from the backend
  try {
    if (!Object.values(TYPES).includes(field?.purpose)) {
      throw new ApiTncError(
        `Set purpose to an usupported value: ${field?.purpose || 'unknown'}`,
      );
    }
  } catch (e) {
    console.error(e.message);
    /* eslint-disable-next-line no-param-reassign */
    field.purpose = TYPES.QUALITY;
  }

  const uniqueFieldNames = uniqueArray(file.header);

  const newField = {
    enabled: true,
    'source-type': SOURCE_TYPES.RAW,
    'header-idx': field.idx,
    'header-name': uniqueFieldNames[field.idx],
    'field-alias': uniqueFieldNames[field.idx],
    purpose: field.purpose,
    'null-value': field.purpose === TYPES.MVALUE ? 0 : null,
    format: field?.format ?? null,
    'map-symbols': {
      arrows: {},
    },
    levels: field.levels,
    nlevels: field.nlevels,
    nrows: file.nrows,
    filename: file.filename,
    'null-value-count': field.null_value_count,
  };

  return mkPurposeSpecificField(newField);
};

/**
 * Field template for implied-mvalue
 * information source: const when mspan is not null
 *
 * used to generate a source item in sources
 * (see also create-etl-field, newDerivedEtlField)
 *
 * ⚠️  Note the limited dependency on mspan
 *
 * @function
 * @param mspan Field::headerview
 * @param currentMvalue headerView["implied-mvalue"]
 * @return field Field::headerview used in sources
 */
export const impliedMvalueField = ({ mspan, nrows, currentMvalue }) => {
  const { filename, 'field-alias': domain } = mspan;
  const newField = {
    enabled: true,
    'source-type': SOURCE_TYPES.IMPLIED,
    'field-alias': currentMvalue.field['field-alias'],
    'header-idx': null,
    purpose: TYPES.MVALUE,
    format: currentMvalue.field.format,
    'null-value': null,
    'map-implied': {
      domain,
      codomain: 1,
    },
    levels: [[1, nrows]],
    nlevels: 1,
    filename,

    // to estimate mvalue statistics and project etlField value
    'null-value-expansion': 0, // default for etlField equivalent
    'null-value-count': mspan['null-value-count'],
    nrows: mspan.nrows,
  };
  return mkPurposeSpecificField(newField);
};

// local dummy for the next function
/**
 * Return a new wide-field within the collection of wtlf.fields.
 * Relies on an instantiated config prop.
 *
 * ⬜ update time.reference when arrows are updated for mspan
 *
 * 👍 Prior ui is integrated here when available.
 *
 * 👎 Manually coordinate the logic with how this field is updated and
 *    how the mvalue is instantiated (meaFieldFromConfig).
 *
 * @function
 * @param {string} factorName
 * @param {Objecet} config
 * @param {?FileField} prevField
 * @returns wideToLongFields headerView prop
 *
 */
export const longFieldFromFactor = ({ factor, config, prevField, DEBUG = false }) => {
  if (DEBUG) {
    console.group(
      `%cApplying: longFieldFromFactor with alias: ${factor.name || 'empty'} `,
      COLOR,
    );
  }

  const {
    format = null,
    'map-fieldnames': mapFieldnames = {
      // ui or regex (parser)
      arrows: arrows({
        mvalues: config['field-aliases'],
        values: [],
      }),
    },
    levels = [],
    'map-symbols': mapSymbols = { arrows: {} },
  } = prevField || {};

  const newField = {
    enabled: true, // fixed when included in wideToLongFields config
    'source-type': SOURCE_TYPES.WIDE,
    'field-alias': factor.name, // ui using this config
    'header-idx': null, // fixed
    purpose: factor.purpose, // wideToLongFields config mcomp | mspan
    'header-idxs': config['header-idxs'], // fixed
    'field-aliases': config['field-aliases'], // fixed
    'alias-idx-map': config['alias-idx-map'], // fixed
    filename: config.filename, // fixed
    format, // ui
    levels, // set when update arrows
    nlevels: null, // set when update arrows
    'map-symbols': mapSymbols,
    'map-fieldnames': mapFieldnames,
  };
  if (DEBUG) {
    console.debug('prevField');
    console.dir(prevField);
    console.debug('newField');
    console.dir(newField);
  }
  console.groupEnd();

  return mkPurposeSpecificField({ ...prevField, ...newField });
};

/**
 * mvalue "proper" from array of wide-fields
 * (a field in wideToLongFields.fields)
 *
 * @function
 * @param {Object} config
 * @param {EtlField?} EtlField
 * @param {Bool} DEBUG
 * @return {EtlField}
 */
export function meaFieldFromWideConfig({ config, prevField = {}, DEBUG = false }) {
  if (DEBUG) console.groupCollapsed(`%capplying: meaFieldFromWideConfig`, COLOR);

  const newField = {
    enabled: true, // ui
    'source-type': SOURCE_TYPES.WIDE,
    'field-alias': config.mvalue, // ui using this config
    'header-idx': null, // fixed
    purpose: TYPES.MVALUE, // fixed
    format: prevField?.format || null, // ui
    'header-idxs': config['header-idxs'], // fixed
    'field-aliases': config['field-aliases'], // fixed
    'alias-idx-map': config['alias-idx-map'], // fixed
    levels: [], // set when update arrows
    nlevels: prevField?.nlevels || null, // sum of header mvalue nlevels
    filename: config.filename, // fixed
  };

  if (DEBUG) {
    console.debug('prevField');
    console.dir(prevField);
    console.debug('newField');
    console.dir(newField);
    console.groupEnd();
  }
  return mkPurposeSpecificField({ ...prevField, ...newField });
}

/*
type argsForCreateSource = {
  alias: Name,
  codomainReducer: CodomainReducer,
  constant: Filename,
  filename: Filename,
  level: any,
  purpose: Purpose,
  subject: Name,
};
*/

/**
 * Etl field
 * Called by etlField when need to backfill a source
 *
 * @function
 * @param {Object} args
 * @param {String} args.alias
 * @param {String} args.filename
 * @param {String} args.purpose
 * @param {Object} args.subject
 * @param {String} args.codomainReducer
 * @return {Object} source prop
 *
 */
export const createImpliedSource = (args) => {
  const {
    alias,
    codomainReducer,
    mapWeights,
    constant,
    filename,
    level,
    purpose,
    subject,
  } = args;

  const newSource = {
    enabled: true,
    'map-implied': {
      domain: subject,
      codomain: constant,
    },
    'header-idx': null, // the derived field is not part of the header
    'field-alias': alias,
    'source-type': SOURCE_TYPES.IMPLIED,
    purpose,
    'null-value': null,
    format: null,
    'map-symbols': {
      arrows: {},
    },
    levels: [level], // include?
    nlevels: 1,
    filename,
  };

  if (codomainReducer) newSource['codomain-reducer'] = codomainReducer;
  if (mapWeights) newSource['map-weights'] = mapWeights;

  return mkPurposeSpecificField(newSource);
};

/**
 *
 * Customize newField instances
 *
 * Utilized by this module and update-field.
 *
 * If exists or provided, preserves previous values otherwise
 * replaces with a default.
 *
 * 🚧 Success is:
 *
 * * provides the minimum fields required to avoid runtime errors
 * * does not overwrite field props
 *
 * Purpose is specified and updated at two points:
 *
 * 👉 the inspection script makes a "first-guess"
 * 👉 ui in the `file-dialog` (list of selected files)
 * 👉 other ui: wide-to-long, quality | mcomp from "group-by-file"
 *
 * See: @flow docs that specify the field type
 *      coordinate with create-etl-field
 *
 * 🚨 Sets some versions of levels prop to empty array. An issue when
 * the purpose is toggled back to something that reads levels (e.g., time).
 *
 * @function
 * @param {FileField} field
 * @return {FileField} now purpose-specific
 */
export function mkPurposeSpecificField(field, DEBUG = false) {
  if (DEBUG) {
    console.debug(
      `headerview-field is customizing the field: ${field['field-alias'] || 'empty'}`,
    );
  }

  /* eslint-disable no-param-reassign */
  switch (field.purpose) {
    case TYPES.SUBJECT: {
      field = H.removeProp(
        ['time', 'levels-mspan', 'codomain-reducer', 'slicing-reducer'],
        field,
      );
      field.levels = [];
      break;
    }
    case TYPES.QUALITY: {
      field = H.removeProp(['time', 'levels-mspan', 'slicing-reducer'], field);

      // there can only be one value of quality; de-dup by...
      field['codomain-reducer'] = field['codomain-reducer'] || 'FIRST'; // user input

      field['map-weights'] = field['map-weights'] || {
        arrows: {},
      };
      break;
    }
    case TYPES.MVALUE: {
      field = H.removeProp(['time', 'levels-mspan', 'map-weights'], field);
      field.levels = [];

      field['codomain-reducer'] = field['codomain-reducer'] || 'SUM'; // user input
      field['slicing-reducer'] = field['slicing-reducer'] || 'SUM'; // user input
      break;
    }
    case TYPES.MCOMP: {
      field = H.removeProp(
        ['time', 'levels-mspan', 'codomain-reducer', 'slicing-reducer'],
        field,
      );
      field['map-weights'] = field['map-weights'] || {
        arrows: {},
      };
      break;
    }
    case TYPES.MSPAN: {
      field = H.removeProp(
        ['map-weights', 'codomain-reducer', 'slicing-reducer'],
        field,
      );
      field.time = field.time || initialTimeProp;
      field['levels-mspan'] = field['levels-mspan'] || undefined;
      break;
    }
    default: {
      throw new ValueError(
        `Customizing headerview field with an invalid purpose value: ${field.purpose}`,
      );
    }
  }
  return field;
  /* eslint-enable no-param-reassign */
}

/**
 * template for 'map-fieldnames'.arrows
 * :: ([fieldNames::mvalues], [values]) -> arrows::Object
 */
export function arrows({ mvalues, values = [] }) {
  /* eslint-disable no-shadow, no-param-reassign */
  return mvalues.reduce((arrows, name, idx) => {
    arrows[name] = values[idx] || '';
    return arrows;
  }, {});
  /* eslint-enable no-shadow, no-param-reassign */
}
