import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

/* eslint-disable react/jsx-props-no-spreading */

const DraggableIcon = (props) => (
  <SvgIcon
    width='8'
    height='27'
    viewBox='0 0 8 27'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.50008 3.66665C2.95841 3.66665 3.33341 3.25415 3.33341 2.74998C3.33341 2.24581 2.95841 1.83331 2.50008 1.83331C2.04175 1.83331 1.66675 2.24581 1.66675 2.74998C1.66675 3.25415 2.04175 3.66665 2.50008 3.66665ZM2.50008 4.58331C2.04175 4.58331 1.66675 4.99581 1.66675 5.49998C1.66675 6.00415 2.04175 6.41665 2.50008 6.41665C2.95841 6.41665 3.33341 6.00415 3.33341 5.49998C3.33341 4.99581 2.95841 4.58331 2.50008 4.58331ZM2.50008 7.33331C2.04175 7.33331 1.66675 7.74581 1.66675 8.24998C1.66675 8.75415 2.04175 9.16665 2.50008 9.16665C2.95841 9.16665 3.33341 8.75415 3.33341 8.24998C3.33341 7.74581 2.95841 7.33331 2.50008 7.33331Z'
      fill='black'
      fillOpacity='0.54'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.50008 3.66665C5.95841 3.66665 6.33341 3.25415 6.33341 2.74998C6.33341 2.24581 5.95841 1.83331 5.50008 1.83331C5.04175 1.83331 4.66675 2.24581 4.66675 2.74998C4.66675 3.25415 5.04175 3.66665 5.50008 3.66665ZM5.50008 4.58331C5.04175 4.58331 4.66675 4.99581 4.66675 5.49998C4.66675 6.00415 5.04175 6.41665 5.50008 6.41665C5.95841 6.41665 6.33341 6.00415 6.33341 5.49998C6.33341 4.99581 5.95841 4.58331 5.50008 4.58331ZM5.50008 7.33331C5.04175 7.33331 4.66675 7.74581 4.66675 8.24998C4.66675 8.75415 5.04175 9.16665 5.50008 9.16665C5.95841 9.16665 6.33341 8.75415 6.33341 8.24998C6.33341 7.74581 5.95841 7.33331 5.50008 7.33331Z'
      fill='black'
      fillOpacity='0.54'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.50008 11.6666C2.95841 11.6666 3.33341 11.2541 3.33341 10.75C3.33341 10.2458 2.95841 9.83331 2.50008 9.83331C2.04175 9.83331 1.66675 10.2458 1.66675 10.75C1.66675 11.2541 2.04175 11.6666 2.50008 11.6666ZM2.50008 12.5833C2.04175 12.5833 1.66675 12.9958 1.66675 13.5C1.66675 14.0041 2.04175 14.4166 2.50008 14.4166C2.95841 14.4166 3.33341 14.0041 3.33341 13.5C3.33341 12.9958 2.95841 12.5833 2.50008 12.5833ZM2.50008 15.3333C2.04175 15.3333 1.66675 15.7458 1.66675 16.25C1.66675 16.7541 2.04175 17.1666 2.50008 17.1666C2.95841 17.1666 3.33341 16.7541 3.33341 16.25C3.33341 15.7458 2.95841 15.3333 2.50008 15.3333Z'
      fill='black'
      fillOpacity='0.54'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.50008 11.6666C5.95841 11.6666 6.33341 11.2541 6.33341 10.75C6.33341 10.2458 5.95841 9.83331 5.50008 9.83331C5.04175 9.83331 4.66675 10.2458 4.66675 10.75C4.66675 11.2541 5.04175 11.6666 5.50008 11.6666ZM5.50008 12.5833C5.04175 12.5833 4.66675 12.9958 4.66675 13.5C4.66675 14.0041 5.04175 14.4166 5.50008 14.4166C5.95841 14.4166 6.33341 14.0041 6.33341 13.5C6.33341 12.9958 5.95841 12.5833 5.50008 12.5833ZM5.50008 15.3333C5.04175 15.3333 4.66675 15.7458 4.66675 16.25C4.66675 16.7541 5.04175 17.1666 5.50008 17.1666C5.95841 17.1666 6.33341 16.7541 6.33341 16.25C6.33341 15.7458 5.95841 15.3333 5.50008 15.3333Z'
      fill='black'
      fillOpacity='0.54'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.50008 19.6666C2.95841 19.6666 3.33341 19.2541 3.33341 18.75C3.33341 18.2458 2.95841 17.8333 2.50008 17.8333C2.04175 17.8333 1.66675 18.2458 1.66675 18.75C1.66675 19.2541 2.04175 19.6666 2.50008 19.6666ZM2.50008 20.5833C2.04175 20.5833 1.66675 20.9958 1.66675 21.5C1.66675 22.0041 2.04175 22.4166 2.50008 22.4166C2.95841 22.4166 3.33341 22.0041 3.33341 21.5C3.33341 20.9958 2.95841 20.5833 2.50008 20.5833ZM2.50008 23.3333C2.04175 23.3333 1.66675 23.7458 1.66675 24.25C1.66675 24.7541 2.04175 25.1666 2.50008 25.1666C2.95841 25.1666 3.33341 24.7541 3.33341 24.25C3.33341 23.7458 2.95841 23.3333 2.50008 23.3333Z'
      fill='black'
      fillOpacity='0.54'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.50008 19.6666C5.95841 19.6666 6.33341 19.2541 6.33341 18.75C6.33341 18.2458 5.95841 17.8333 5.50008 17.8333C5.04175 17.8333 4.66675 18.2458 4.66675 18.75C4.66675 19.2541 5.04175 19.6666 5.50008 19.6666ZM5.50008 20.5833C5.04175 20.5833 4.66675 20.9958 4.66675 21.5C4.66675 22.0041 5.04175 22.4166 5.50008 22.4166C5.95841 22.4166 6.33341 22.0041 6.33341 21.5C6.33341 20.9958 5.95841 20.5833 5.50008 20.5833ZM5.50008 23.3333C5.04175 23.3333 4.66675 23.7458 4.66675 24.25C4.66675 24.7541 5.04175 25.1666 5.50008 25.1666C5.95841 25.1666 6.33341 24.7541 6.33341 24.25C6.33341 23.7458 5.95841 23.3333 5.50008 23.3333Z'
      fill='black'
      fillOpacity='0.54'
    />
  </SvgIcon>
);
export default DraggableIcon;
